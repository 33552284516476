<template>
    <div class="row justify-content-center">
        <b-card
                header-tag="header"
                footer-tag="footer"
                title="更改你的電子郵件"
        >
            <b-card-text v-if="isMailDiff">你的電子郵件尚未完成變更，<br>目前的電子郵件為：{{s_email}}</b-card-text>
            <b-form>
                <b-form-group
                        label="電子郵件："
                        label-for="input-1"
                        :description="emailMsg"
                >
                    <b-form-input
                            v-model="f_mail"
                            type="email"
                            required
                            placeholder="輸入你的電子郵件"
                    />
                </b-form-group>
                <b-form-group>
                  <b-button v-on:click="verifyMail"
                            variant="primary"
                            :disabled="sendStatus"
                            block
                  >
                    更改電子郵件並寄出驗證信
                  </b-button>
                </b-form-group>
            </b-form>
            <template v-slot:footer>
                <b-row class="text-center">
                    <b-col><b-button variant="info" :to="{name: 'student_area_home'}">返回</b-button></b-col>
                    <!--
                    <b-col><b-button :disabled="!reloadBtn.enable" v-on:click="reload">{{reloadBtn.reloadMsg}}</b-button></b-col>
                     -->
                </b-row>
            </template>

        </b-card>
    </div>
</template>

<script>
    import firebase from "firebase";
    import axios from "axios";

    export default {
        name: "Email",
        data: function () {
            return {
                f_mail: "載入中",
                s_email: "載入中~",
                emailErr: {
                    isErr: false,
                    errMsg: ""
                },
                emailMsg: "",
                sendStatus: false,
                reloadBtn: {
                    reloadMsg: "重新整理",
                    enable: true
                }

            }
        },
        mounted: function () {
            firebase.auth().onAuthStateChanged((user) => {
                if (user){
                    axios.get('/api/user/me')
                        .then((response) => {
                            this.f_mail = user.email;
                            this.s_email = response.data.email;
                        })
                        .catch((error) => {
                            window.console.log(error);
                        })
                }
            })
        },
        computed: {
            isMailDiff(){
                return this.f_mail !== this.s_email;
            }
        },
        methods: {
            verifyMail: function () {
                this.emailMsg = "";
                this.sendStatus= true;
                if (this.s_email === this.f_mail){
                    this.emailMsg = "您的電子郵件並未變更。";
                }else{
                    firebase.auth().currentUser.updateEmail(this.f_mail)
                        .then(()=>{
                            firebase.auth().currentUser.sendEmailVerification()
                                .then(()=>{
                                    this.emailMsg = "已經寄出確認信函，請查收您的電子信箱。";
                                    this.sendStatus= false;
                                })
                        })
                        .catch((error)=>{
                            if (error.code === "auth/requires-recent-login"){
                                firebase.auth().signOut()
                                    .then(() => {
                                        axios.get('/api/auth/get_firebase_token')
                                            .then(function (response) {
                                                firebase.auth().signInWithCustomToken(response.data.token)
                                                    .then(() => {
                                                        this.verifyMail();
                                                    })
                                                    .catch(function (error) {
                                                        window.console.log(error);
                                                    })
                                            })
                                    })
                            }else{
                                window.console.log(error);
                            }
                        })
                }
            },
           /*
           *  reload: function () {
                this.emailMsg = "";
                this.reloadBtn.reloadMsg = "資料重新載入中…";
                this.reloadBtn.enable = false;
                firebase.auth().currentUser.reload()
                .then(()=>{
                    axios.get('/api/user/me')
                        .then((response) => {
                            this.f_mail = firebase.auth().currentUser.email;
                            this.s_email = response.data.email;
                            this.reloadBtn.reloadMsg = "重新載入資料";
                            this.reloadBtn.enable = true;
                        })
                        .catch((error) => {
                            window.console.log(error);
                        })
                });

            }
           * */
        }
    }
</script>

<style scoped>

</style>
