<template>
    <div class="row justify-content-center">
        <div v-show="wrong">
            <h3>好像哪裡出問題了</h3>
            <p>一些可能有用的訊息：<br>{{wrongMsg}}</p>
            <b-col><b-button variant="info" :to="{name: 'student_area_home'}">回到資料總攬</b-button></b-col>
        </div>
        <b-card
                header-tag="header"
                footer-tag="footer"
                title="其他可選資料"
                v-show="allDone"
        >
            <b-form>
                <b-form-group
                        id="engName"
                        description="讓我們知道你的英文名字"
                        label="英文名字"
                >
                    <b-form-input type="text" v-model="englishName"/>
                </b-form-group>
                <b-form-group
                        id="eme"
                        description="希望可以輸入類似這樣的文字：爸爸-黃大名 或是 朋友-陳先生"
                        label="緊急聯絡人稱呼方式"
                >
                    <b-form-input type="text" v-model="emec"/>
                </b-form-group>
                <b-form-group
                        id="emec"
                        description="台灣的電話就可以囉～"
                        label="緊急聯絡人聯絡方式"
                >
                    <b-form-input type="text" v-model="emecp"/>
                </b-form-group>
            </b-form>
            <template v-slot:footer>
                <b-row class="text-center">
                    <b-col><b-button variant="info" :to="{name: 'student_area_home'}">回到資料總攬</b-button></b-col>
                    <b-col><b-button :disabled="running" v-on:click="submit">{{runBtn}}</b-button></b-col>
                </b-row>
            </template>
        </b-card>
    </div>
</template>

<script>
    import axios from "axios";

    export default {
        name: "Connection",
        data: function () {
            return{
                englishName: "",
                emec: "",
                emecp: "",
                running: false,
                runBtn: "更新資料",
                allDone: false,
                wrong: false,
                wrongMsg: ""
            }
        },
        mounted: function () {
            axios.get('/api/user/me')
                .then((response) => {
                    this.emec = response.data.emc;
                    this.emecp = response.data.emcp;
                    this.englishName = response.data.en_name;
                    this.allDone = true;
                })
                .catch((error) => {
                    window.console.log(error);
                    this.wrong = true;
                    this.wrongMsg = error.message;
                });
        },
        methods: {
            submit: function () {
                let temp={
                    "english_name": this.englishName,
                    "emergency_contact": this.emec,
                    "emergency_contact_phone": this.emecp
                };
                this.running = true;
                this.runBtn = "更新中…";
                axios.post('/api/user/me', temp)
                .then(()=>{
                    this.running = false;
                    this.runBtn = "完成，再次更新資料";
                })
            }
        }
    }
</script>

<style scoped>

</style>