<template>
  <div>
    <router-view/>
    <div v-if="isOnThisPage" class="row justify-content-center">
      <div v-show="isWrong">
        <h3>好像哪裡出問題了</h3>
        <p>一些可能有用的訊息：<br>
          created：{{ loading.createdMsg }}<br>
          mounted：{{ loading.mountedMsg }}
        </p>
        <b-col>
          <b-button :to="{name: 'home'}" variant="info">回到首頁</b-button>
        </b-col>
      </div>
      <div v-if="allDone" class="text-center col-N">
        <h2>{{ this.studentId }} {{ name }} 你好</h2>
        <p class="lead">您上次確認此資料的時間為{{ time }}</p>
        <p v-if="!isAllRight">請確認下列資料<br/>
          電子郵件與手機號碼為必填項目
        </p>
        <template v-if="isAllRight">
          <template v-if="room.status === '已入住'">
            <h4>請至鑰匙簽領處出示此畫面</h4>
            <h4>你的床位是︰{{ room.name }}</h4>
            <h5 v-if="lineGroupURL.length">樓層LINE群組連結︰<a :href="this.lineGroupURL">{{this.lineGroupURL}}</a></h5>
            <h5 v-else>群組連結不存在，請聯繫辦公室或樓長</h5>
          </template>
          <template v-else>
            <h4>請先至報到處進行報到</h4>
            <img id="stuidCode">
            <div
              class="col-12">
              <b-button
                variant="success"
                @click="refresh"
              >
                點我領取鑰匙
              </b-button>
            </div>
          </template>
        </template>

        <div class="card text-left">
          <b-list-group class="list-group">
            <b-list-group-item :class="{red:(this.email_card.value === null)}"
                               :to="{name: 'student_area_email'}"
                               class="list-group-item list-group-item-action flex-column align-items-start">
              <div class="d-flex w-100 justify-content-between">
                <h5 class="mb-1">電子郵件</h5>
                <small>按此可以更改</small>
              </div>
              <p class="mb-1">您目前的電子郵件是：<br>{{ show_email }}</p>
              <p v-if="mailIsDiff" class="mb-1">你的電子郵件正準備變更為：{{ this.email_card.f_value }}；按下以完成變更。</p>
              <small class="text-muted">電子郵件是很重要的聯絡資訊，請務必保持正確。</small>
            </b-list-group-item>
            <b-list-group-item :class="{red:(this.email_card.value === null)}"
                               :to="{name: 'student_area_phone'}"
                               class="list-group-item list-group-item-action flex-column align-items-start">
              <div class="d-flex w-100 justify-content-between">
                <h5 class="mb-1">手機號碼</h5>
                <small>按此可以更改</small>
              </div>
              <p class="mb-1">您目前的手機號碼是：<br>{{ show_phone }}</p>
              <small class="text-muted">手機號碼用於緊急事項聯絡，不過更常用於回覆各位的問題。</small>
            </b-list-group-item>
            <b-list-group-item :to="{name: 'student_area_option'}"
                               class="list-group-item list-group-item-action flex-column align-items-start">
              <div class="d-flex w-100 justify-content-between">
                <h5 class="mb-1">其他非必要資訊：</h5>
                <small>按此可以更改</small>
              </div>
              <p class="mb-1">英文姓名：{{ others.en_name }}<br>緊急聯絡人：{{ others.emc }}<br>緊急聯絡人電話：{{ others.emcp }}</p>
              <small class="text-muted">英文姓名會用在查詢國外包裹，其他部分真的是緊急用，以上都不是必填。</small>
            </b-list-group-item>
            <!--
            <b-list-group-item v-on:click="ckickLineBtn" class="list-group-item list-group-item-action flex-column align-items-start" v-bind:disabled="lineClickMsg in ['載入中', '狀態不明']">
                  <div class="d-flex w-100 justify-content-between">
                      <h5 class="mb-1"><b-spinner v-show="lineClickMsg === '狀態不明'"/>加Line：{{lineBigMsg}}</h5>
                      <small>{{lineClickMsg}}</small>
                  </div>
                  <p class="mb-1">Line可以用來及時通知很多訊息</p>
                  <small class="text-muted">簡訊要錢，但Line不用。</small>
              </b-list-group-item>
              <b-list-group-item class="list-group-item list-group-item-action flex-column align-items-start">
                  <div class="d-flex w-100 justify-content-between">
                      <h5 class="mb-1">門禁例外申請（開發中）：</h5>
                      <small>按此還不行進去</small>
                  </div>
                  <p class="mb-1">等我～～～！</p>
                  <small class="text-muted">可以一次申請七天的樣子</small>
              </b-list-group-item>-->
          </b-list-group>
          <div v-if="!isDataFresh">
            <b-button block size="lg" variant="success" v-on:click="updateModifyTime">我確定資料沒有問題</b-button>
          </div>
        </div>
      </div>
      <div v-else>
        <b-spinner label="Large Spinner" style="width: 3rem; height: 3rem;"/>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import firebase from "firebase";

import jsbarcode from 'jsbarcode'


export default {
  name: "SpacePage",
  computed: {
    uuid() {
      return this.$store.state.userState.uuid
    },
    isOnThisPage() {
      return this.$route.path === "/student"
    },
    name() {
      return this.$store.state.userState.name;
    },
    room() {
      const histories = this.$store.state.userState.activeBedHistories;
      return histories.length ? histories[0] : "";
    },
    mailIsDiff() {
      return this.email_card.f_value !== this.email_card.value;
    },
    show_email() {
      if (this.email_card.value === null) {
        return "尚未輸入";
      } else {
        return this.email_card.value
      }
    },
    show_phone() {
      if (this.phone_card.number === null) {
        return "尚未輸入";
      } else {
        return this.phone_card.number;
      }
    },
    allDone() {
      return !!(this.loading.created && this.loading.mounted)
    },
    isWrong() {
      return !!(this.loading.createdMsg !== "" || this.loading.mountedMsg !== "")
    },
    isDataFresh() {
      return Date.now() - this.updateTimeObject < 86400 * 31 * 3 * 1000
    },
    isAllRight() {
      return this.isDataFresh && this.email_card.isConfirm && this.phone_card.number !== null
    },
    lineClickMsg() {
      switch (this.line.LinkStatus) {
        case 'linked':
          return '按此取消連結';
        case 'unknown':
          return "狀態不明";
        case 'unlink':
          return '按此加Line好友'
        case 'wait':
          return '完成後按此更新狀態';
        default:
          return '狀態不明';
      }
    },
    lineBigMsg() {
      switch (this.line.LinkStatus) {
        case 'linked':
          return '已連結';
        case 'unknown':
          return "狀態不明";
        case 'unlink':
          return '尚未連結'
        case 'wait':
          return '等待中';
        default:
          return '狀態不明';
      }
    }
  },
  created: function () {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        //window.console.log(user);
        if (this.uuid !== user.uid) {
          firebase.auth().signOut();
        }
      }
    })
    axios.get('/api/auth/get_firebase_token')
    .then(function (response) {
      //window.console.log("登入");
      firebase.auth().signInWithCustomToken(response.data.token)
      .then(() => {
      })
      .catch(function (error) {
        window.console.log(error);
      })
    })
    .catch((error) => {
      window.console.log(error);
      if (error.response.status === 500) {
        window.console.log(error.response.status);
        this.$store.commit('errorHandle/raiseError', "不明錯誤，建議登出重載應用程式");
      } else if (error.response.status === 401) {
        this.$store.dispatch("verifyStatus");
      }
    })
  },
  components: {},
  data: function () {
    return {
      time: "",
      studentId: "",
      email_card: {
        isConfirm: true,
        value: "載入中…",
        f_value: "載入中…"  // 來自Firebase的值
      },
      phone_card: {
        number: "載入中…",
      },
      others: {
        emc: "",
        emcp: "",
        en_name: ""
      },
      line: {
        LinkStatus: 'unknown',
        info: '',
      },
      lineGroupURL: '',
      loading: {
        mounted: false,
        created: true,
        createdMsg: "",
        mountedMsg: ""
      },
      updateTimeObject: Date.now()
    }
  },
  mounted: function () {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        this.email_card.isConfirm = user.emailVerified;
        this.email_card.f_value = user.email;
        this.phone_card.number = user.phoneNumber;
        axios.get('/api/user/me')
        .then((response) => {
          this.email_card.value = response.data.email;
          if (response.update_time === 0) {
            this.time = "尚未完成首次更新"
          } else {
            let dt = new Date(response.data.update_time * 1000);
            this.updateTimeObject = dt;
            this.time = " " + dt.toLocaleString();
          }
          this.others.emc = response.data.emc;
          this.others.emcp = response.data.emcp;
          this.others.en_name = response.data.en_name;
          this.loading.mounted = true;
          if (response.data.hasOwnProperty('oneOfStuid') && this.isOnThisPage) {
            this.studentId = response.data.oneOfStuid;
            this.$nextTick(() => {
              jsbarcode("#stuidCode", response.data.oneOfStuid, {text: " "});
            })
          }
        })
        .catch((error) => {
          window.console.log(error);
        })
        axios.get('/api/authority/line-info')
        .then((response) => {
          this.line = response.data;
        }).catch(() => {
        });
        axios.get('/api/authority/line-info')
        .then((response) => {
          this.line = response.data;
        }).catch(() => {
        });
        axios.get('/api/user/line-url')
        .then((response) => {
          if (response.status === 200) {
            this.lineGroupURL = response.data
          }
        });
      }
    })
  },
  watch: {
    isOnThisPage: function (newValue) {
      firebase.auth().onAuthStateChanged((user) => {
        if (user) {
          this.email_card.isConfirm = user.emailVerified;
          this.email_card.f_value = user.email;
          this.phone_card.number = user.phoneNumber;
          axios.get('/api/user/me')
          .then((response) => {
            this.email_card.value = response.data.email;
            if (response.update_time === null) {
              this.time = "尚未完成首次更新"
            } else {
              let dt = new Date(response.data.update_time * 1000);
              this.time = " " + dt.toLocaleString();
            }
            this.others.emc = response.data.emc;
            this.others.emcp = response.data.emcp;
            this.others.en_name = response.data.en_name;
            if (response.data.hasOwnProperty('oneOfStuid') && newValue) {
              this.$nextTick(() => {
                jsbarcode("#stuidCode", response.data.oneOfStuid, {text: "給工讀生掃描"});
              })
            }
          })
          .catch((error) => {
            window.console.log(error);
          })
        }
      })
    }
  },
  methods: {
    updateModifyTime: function () {
      axios.post('/api/user/me')
      .then(() => {
        axios.get('/api/user/me', {})
        .then((response) => {
          this.email_card.value = response.data.email;
          if (response.update_time === 0) {
            this.time = "尚未完成首次更新"
          } else {
            let dt = new Date(response.data.update_time * 1000);
            this.updateTimeObject = dt;
            this.time = " " + dt.toLocaleString();
          }
          this.others.emc = response.data.emc;
          this.others.emcp = response.data.emcp;
          this.others.en_name = response.data.en_name;
          this.loading.mounted = true;
          if (response.data.hasOwnProperty('oneOfStuid')) {
            this.$nextTick(() => {
              jsbarcode("#stuidCode", response.data.oneOfStuid, {text: "給工讀生掃描"});
            })
          }
        })
        .catch((error) => {
          window.console.log(error);
        })
      }).catch(() => {
      })
    },
    ckickLineBtn: function () {

      axios.get('/api/authority/line-info')
      .then((response) => {
        if (this.line.LinkStatus === "wait") {
          this.line = response.data;
          return null
        }
        this.line = response.data;
        let url = 'https://notify-bot.line.me/oauth/authorize?response_type=code&client_id=14JIxmzZQvhtzet9FgV2P1&redirect_uri=https://dorm.nttu.club/api/authority/line-notify&scope=notify&&state=' + this.line.info;
        /*if(this.line.LinkStatus === "unlink"){
            this.line.LinkStatus = 'wait';
            window.open(url);
        }else if(this.line.LinkStatus === "linked"){
            this.line.LinkStatus = 'wait';
            axios.post('/api/authority/line-info')
                .then((response)=>{
                    window.alert(response.data.msg);
                    this.line.LinkStatus = 'unlink';
                })
                .catch(()=>{});
        }*/
        switch (this.line.LinkStatus) {
          case "unknown":
            break;
          case "unlink":
            this.line.LinkStatus = 'wait';
            window.location = url;
            break;
          case "linked":
            this.line.LinkStatus = 'wait';
            axios.post('/api/authority/line-info')
            .then((response) => {
              window.alert(response.data.msg);
              this.line.LinkStatus = 'unlink';
            })
            .catch(() => {
            });
            break;
          case 'wait':
            window.alert('載入中，請稍後');
            break;
        }
      }).catch(() => {
        this.line.LinkStatus = 'unknown';
        this.line.info = '';
      });
    },
    refresh() {
      window.location.reload();
    }
  },

}
</script>

<style scoped>
.red {
  background: #f4cccc;
}
</style>
