<template>
    <div class="row justify-content-center">
        <b-card
                header-tag="header"
                footer-tag="footer"
                title="更改你的手機號碼"
                sub-title="請耐心等候驗證碼"
        >
            <b-form>
                <b-form-group
                        label="手機號碼："
                        label-for="input-1"
                >
                    <b-form-input
                            v-model="phoneNumber"
                            type="tel"
                            required
                            placeholder="輸入你的手機號碼"
                            :disabled="sendDisable"
                            trim
                    />
                </b-form-group>
                <b-form-group>
                  <b-button v-on:click="sendSms" :disabled="sendDisable" block>{{sendBtnMsg}}</b-button>
                </b-form-group>
                <b-form-group
                        label="簡訊驗證碼："
                        label-for="input-2"
                >
                    <b-form-input
                            v-model="phoneAuthCode"
                            type="tel"
                            required
                            placeholder="來自簡訊的驗證碼"
                            :disabled="!sendDisable"
                            trim
                    />
                </b-form-group>
              <b-form-group>
                <b-button v-on:click="verCode" :disabled="!sendDisable" block>確認更改手機號碼</b-button>
              </b-form-group>
            </b-form>
            <template v-slot:footer>
                <b-row class="text-center">
                    <b-col><b-button variant="info" :to="{name: 'student_area_home'}">返回</b-button></b-col>
                </b-row>
            </template>
        </b-card>
        <div id="auth_div"></div>
    </div>
</template>

<script>
    import firebase from "firebase";

    export default {
        name: "Phome",
        data: function () {
            return{
                phoneNumber: "",
                phoneAuthCode: "",
                objects: {
                    applicationVerifier: null,
                    applicationVerifierStandBy: false,
                    verificationId: null,
                    errorMsg: ""

                },
                sendDisable: false,
                sendBtnMsg: "寄出簡訊驗證碼"
            }
        },
        mounted: function () {
            this.objects.applicationVerifier = new firebase.auth.RecaptchaVerifier(
                'auth_div', {
                    'size': 'invisible',
                    'callback': () => {
                        this.objects.applicationVerifierStandBy= true;
                    }
                }
            );
            firebase.auth().languageCode = 'zh-TW';
            firebase.auth().onAuthStateChanged((user)=>{
                if(user){
                    if (user.phoneNumber !== null){
                        this.phoneNumber = user.phoneNumber
                    }
                }

            })
        },
        methods: {
            sendSms: function () {
                this.sendBtnMsg = "簡訊寄出中…";
                this.sendDisable = true;
                let tel = this.phoneNumber.trim();
                if (tel[0] === "+"){
                    this.phoneNumber = tel;
                }else if (tel[0] !== "0"){
                    this.phoneNumber = "+886" + tel;
                }else {
                    let temp = this.phoneNumber.substring(1, tel.length);
                    this.phoneNumber = "+886" + temp;
                }
                let provider = new firebase.auth.PhoneAuthProvider();
                provider.verifyPhoneNumber(this.phoneNumber, this.objects.applicationVerifier)
                .then((verificationId)=>{
                    this.sendDisable = true;
                    this.objects.verificationId = verificationId;
                    this.sendBtnMsg = "簡訊已寄發";
                }).catch((error)=>{
                    switch (error.code) {
                        case "auth/invalid-phone-number":
                            this.objects.errorMsg = "手機號碼格式錯誤，請回到上一頁重新再試一次";
                            break;
                        case "auth/auth/captcha-check-failed":
                            this.objects.errorMsg = "反機器人驗證錯誤，請回到上一頁重新再試一次";
                            break;
                        case "auth/missing-phone-number":
                            this.objects.errorMsg = "抓不到手機號碼，請回到上一頁重新再試一次";
                            break;
                        case "auth/user-disabled":
                            this.objects.errorMsg = "您已被禁用，若是有問題請聯絡行政人員。";
                            break;
                        default:
                            window.alert(error.message);
                            this.objects.errorMsg = error.message;
                            break;
                    }
                })
            },
            verCode: function () {
                firebase.auth().onAuthStateChanged((user)=>{
                    let phoneCredential = firebase.auth.PhoneAuthProvider.credential(this.objects.verificationId, this.phoneAuthCode);
                    user.updatePhoneNumber(phoneCredential).then(()=>{
                        window.alert("手機變更成功");
                        this.$router.push({name: "student_area_home"});
                    }).catch((error)=>{
                        if (error.code === 'auth/invalid-verification-code') {
                            window.alert("驗證碼錯誤，請回到上一頁重新再試一次");
                            this.$router.push({name: "student_area_home"});
                        }else {
                            window.alert(error.message);
                            this.$router.push({name: "student_area_home"});
                        }

                    })
                })
            }
        }
    }
</script>

<style scoped>

</style>
